
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import DrawerFichaProfesional from "@/components/servicios/DrawerFichaProfesional.vue";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { titulacionesTodas } from "@/core/data/titulaciones";
import {
  ProfesionalAsignado,
  ProfesionalListado,
  ServicioListadoModule,
} from "@/store/modules";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "profesionales-listado",
  components: { "download-excel": JsonExcel, DrawerFichaProfesional },
  props: {
    profesionales: Object as () => ProfesionalAsignado[],
    inicioPrevisto: Date,
    finPrevisto: Date,
    comarcaId: String,
    servicioId: String,
    profesionalId: String,
    tipologiaJornada: String,
    tipologiaContrato: String,
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const csvLabels = {
      nombre: t("csv.listados.profesionales.nombre"),
      apellidos: t("csv.listados.profesionales.apellidos"),
      telefono: t("csv.listados.profesionales.telefono"),
      email: t("csv.listados.profesionales.email"),
      titulacion: t("csv.listados.profesionales.titulacion"),
      fechaInicioDate: t("csv.listados.profesionales.fechaInicio"),
      fechaFinDate: t("csv.listados.profesionales.fechaFin"),
      tipologiaContrato: t("csv.listados.profesionales.tipoContrato"),
      tipologiaJornada: t("csv.listados.profesionales.tipoJornada"),
    };
    const route = useRoute();
    const token = route.params.id;

    const store = useStore();
    const module = getModule(ServicioListadoModule, store);

    const csvFields = [
      "nombre",
      "apellidos",
      "telefono",
      "email",
      "titulacion",
      "fechaInicioDate",
      "fechaFinDate",
      "tipologiaContrato",
      "tipologiaJornada",
    ];
    const csvName =
      "serviciosProfesionalesListado" + new Date().getTime() + ".xlsx";

    const drawerFicha = ref(false);
    const dialogVisible = ref(false);
    const dialogVisibleModificarFechas = ref(false);
    const fechaFinServicio = ref<Date>();
    const disabledDate = (time: Date) => {
      return (
        moment(time.getTime()).isAfter(props.finPrevisto) ||
        moment(time.getTime()).isBefore(props.inicioPrevisto)
      );
    };

    const sortNombreApellidos = function (a, b) {
      let primero = a.profesional.nombre + " " + a.profesional.apellidos;
      let segundo = b.profesional.nombre + " " + b.profesional.apellidos;
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };

    const profesionalSeleccionado = ref<ProfesionalListado | null>(null);
    const profesionalSeleccionadoFechaInicio = ref<Date>();
    const profesionalSeleccionadoFechaFin = ref<Date>();
    const profesionalSeleccionadoFechaInicioSeleccionada = ref<Date>();
    const profesionalSeleccionadoFechaFinSeleccionada = ref<Date>();
    const fechasModificacionProfesional = computed({
      get(): Date[] {
        if (
          !profesionalSeleccionadoFechaInicioSeleccionada.value ||
          !profesionalSeleccionadoFechaFinSeleccionada.value
        )
          return [];

        return [
          profesionalSeleccionadoFechaInicioSeleccionada.value,
          profesionalSeleccionadoFechaFinSeleccionada.value,
        ];
      },
      set(newValue: Date[]) {
        if (newValue.length != 2) return;

        const [inicio, fin] = newValue;
        profesionalSeleccionadoFechaInicioSeleccionada.value = inicio;
        profesionalSeleccionadoFechaFinSeleccionada.value = fin;
      },
    });

    const nombreCompleto = ref<string>();

    const idProfesional = ref<string>();
    const idProfesionalAsignado = ref<string>();

    const loadingModificarProfesional = ref(false);

    const loadingCancelarProfesional = ref(false);

    const error = computed(() => module.errors);
    const profesionalSeleccionadoJornada = ref<string>();
    const profesionalSeleccionadoContrato = ref<string>();
    return {
      error,
      dialogVisible,
      dialogVisibleModificarFechas,
      fechaFinServicio,
      disabledDate,
      titulacionesTodas,
      profesionalesListado: computed(
        () =>
          props.profesionales?.map((row) => {
            return {
              ...row,
              nombre: row.profesional?.nombre ?? "",
              apellidos: row.profesional?.apellidos ?? "",
              telefono: row.profesional?.telefono ?? "",
              email: row.profesional?.email ?? "",
              fechaInicioDate: new Date(row.fechaInicio),
              fechaFinDate: new Date(row.fechaFin),
              titulacion:
                row.profesional?.titulacion
                  ?.map((t) => titulacionesTodas.filter((a) => a.id == t))
                  ?.map((t) => t.map((r) => r.text))
                  .join(", ") || "",
            };
          }) ?? []
      ),
      tipoContrato: computed(() => props.tipologiaContrato),
      tipoJornada: computed(() => props.tipologiaJornada),
      csvLabels,
      csvFields,
      csvName,
      drawerFicha,
      nombreCompleto,
      profesionalSeleccionado,
      profesionalSeleccionadoFechaInicio,
      profesionalSeleccionadoFechaFin,
      fechasModificacionProfesional,
      profesionalSeleccionadoFechaInicioSeleccionada,
      profesionalSeleccionadoFechaFinSeleccionada,
      profesionalSeleccionadoJornada,
      profesionalSeleccionadoContrato,
      loadingModificarProfesional,
      loadingCancelarProfesional,
      drawerCerrar: () => {
        drawerFicha.value = false;
      },
      verFichaProfesional: (index, row) => {
        profesionalSeleccionadoJornada.value = row.tipologiaJornada;
        profesionalSeleccionadoContrato.value = row.tipologiaContrato;
        profesionalSeleccionado.value = row.profesional;
        drawerFicha.value = true;
      },
      avisoCancelarProfesional: (index, row) => {
        nombreCompleto.value =
          row.profesional.nombre + " " + row.profesional.apellidos;
        dialogVisible.value = true;
        idProfesional.value = row.profesional.id;
        idProfesionalAsignado.value = row.id;
        profesionalSeleccionadoFechaFin.value =
          profesionalSeleccionadoFechaFinSeleccionada.value || row.fechaFin;
      },
      avisoCambioFechasProfesional: (index, row) => {
        profesionalSeleccionadoFechaInicio.value =
          profesionalSeleccionadoFechaInicioSeleccionada.value ||
          row.fechaInicio;
        profesionalSeleccionadoFechaFin.value =
          profesionalSeleccionadoFechaFinSeleccionada.value || row.fechaFin;

        profesionalSeleccionadoFechaInicioSeleccionada.value =
          profesionalSeleccionadoFechaInicio.value;
        profesionalSeleccionadoFechaFinSeleccionada.value =
          profesionalSeleccionadoFechaFin.value;

        nombreCompleto.value =
          row.profesional.nombre + " " + row.profesional.apellidos;
        dialogVisibleModificarFechas.value = true;
        idProfesional.value = row.profesional.id;
        idProfesionalAsignado.value = row.id;
      },
      modificarFechasProfesional: async () => {
        dialogVisibleModificarFechas.value = false;
        const idServicio = props.servicioId || token;

        loadingModificarProfesional.value = true;
        try {
          await module
            .modificarFechasProfesional({
              comarcaId: props.comarcaId as string,
              idServicio: idServicio as string,
              idProfesional: idProfesional.value as string,
              idProfesionalAsignado: idProfesionalAsignado.value as string,
              fechaInicioServicio:
                profesionalSeleccionadoFechaInicioSeleccionada.value as Date,
              fechaFinServicio:
                profesionalSeleccionadoFechaFinSeleccionada.value as Date,
            })
            .then(() => {
              if (!module.errors?.error) {
                Swal.fire({
                  text: t(
                    "formularios.servicioDetalle.modificarFechasProfesional.mensaje.exito"
                  ),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.go(0);
                });
              } else {
                const swalWithBootstrapButtons = Swal.mixin({
                  customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                });
                swalWithBootstrapButtons.fire(
                  t("dialogs.error"),
                  t("dialogs.modificarFechasProfesional.errorText"),
                  "error"
                );
              }
            });
        } catch (err) {
          Swal.fire({
            text: t(
              "formularios.profesionalesAsignadosListado.modificarFechas.error"
            ),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          loadingModificarProfesional.value = false;
        }
      },
      cancelarProfesional: async () => {
        dialogVisible.value = false;
        const idServicio = props.servicioId || token;
        loadingCancelarProfesional.value = true;
        try {
          await module
            .cancelarProfesional({
              comarcaId: props.comarcaId as string,
              idServicio: idServicio as string,
              idProfesional: idProfesional.value as string,
              idProfesionalAsignado: idProfesionalAsignado.value as string,
              fechaFinServicio: fechaFinServicio.value as Date,
            })
            .then(() => {
              if (!module.errors?.error) {
                Swal.fire({
                  text: t("formularios.servicioDetalle.cancelar.mensaje.exito"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.go(0);
                });
              } else {
                const swalWithBootstrapButtons = Swal.mixin({
                  customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                });
                swalWithBootstrapButtons.fire(
                  t("dialogs.error"),
                  t("dialogs.cancelarProfesional.errorText"),
                  "error"
                );
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.profesionalesAsignadosListado.cancelar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          loadingCancelarProfesional.value = false;
        }
      },
      eliminarProfesional: async (index, row) => {
        idProfesional.value = row.profesional.id;
        idProfesionalAsignado.value = row.id;

        Swal.fire({
          text: t(
            "formularios.profesionalesAsignadosListado.desasignar.descripcion"
          ),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: t("dialogs.okButton"),
          cancelButtonText: t("dialogs.cancelButton"),
          reverseButtons: true,
        }).then(async ({ isConfirmed }) => {
          if (!isConfirmed) return;

          const servicioId = props.servicioId || token;

          try {
            await module.eliminarProfesional({
              comarcaId: props.comarcaId as string,
              servicioId: servicioId as string,
              idProfesional: idProfesional.value as string,
            });

            Swal.fire({
              text: t(
                "formularios.profesionalesAsignadosListado.desasignar.exito"
              ),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              router.go(0);
            });
          } catch (err) {
            console.error("Error en eliminarProfesional:", err);
            console.error(err);
            Swal.fire({
              text: t(
                "formularios.profesionalesAsignadosListado.desasignar.error"
              ),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        });
      },
      sortNombreApellidos,
      toLocaleDateString,
    };
  },
});
