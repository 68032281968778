import { TableColumnCtx } from "element-plus/es/components/table/src/table-column/defaults";
import moment from "moment";

function toLocaleDateString(row: any, column: TableColumnCtx<any>) {
  if (column.property == null) return null;

  let data = row[column.property];

  if (column.property.split(".").length > 1) {
    data = column.property.split(".").reduce((prev, v) => prev[v], row);
  }

  if (data == null) return null;

  return moment(data).format("DD/MM/YYYY");
}

function ellipsisOverflow(size) {
  return function (row: any, column: TableColumnCtx<any>) {
    let data = row[column.property];
    if (data == null) return "";

    return data.length > size ? data.substring(0, size) + "..." : data;
  };
}

export { toLocaleDateString, ellipsisOverflow };
