
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { FormularioEstado } from "@/enums";
import { SolicitudServicioColectivo } from "@/store/models";
import { AuthModule, ServiciosProgramadosModule } from "@/store/modules";
import { ArrowDown } from "@element-plus/icons-vue";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "servicios-listado",
  components: { "download-excel": JsonExcel, ArrowDown },
  props: {
    servicios: Object as () => SolicitudServicioColectivo[],
  },

  methods: {
    formatLongDate(fecha) {
      return moment(fecha).locale("es").format("L");
    },
    formatHour(fecha) {
      return moment(fecha).format("HH:mm");
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);
    const authModule = getModule(AuthModule, store);
    const userComarcaId = authModule.user.comarcaId;

    const csvLabels = {
      fechaDate: t("csv.listados.servicios.fecha"),
      responsable: t("csv.listados.servicios.responsable"),
      dni: t("csv.listados.servicios.dni"),
      familiaAprobada: t("csv.listados.servicios.familiaAprobada"),
      menoresNombres: t("csv.listados.servicios.menor"),
      fechaNacimientoDate: t("csv.listados.servicios.fechaNacimiento"),
      telefono: t("csv.listados.servicios.telefono"),
      localidad: t("csv.listados.servicios.localidad"),
      email: t("csv.listados.servicios.email"),
      estado: t("csv.listados.servicios.estado"),
    };

    const csvFields = [
      "fechaDate",
      "responsable",
      "dni",
      "familiaAprobada",
      "menoresNombres",
      "fechaNacimientoDate",
      "telefono",
      "localidad",
      "email",
      "estado",
    ];
    const csvName = "serviciosListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const sortFamiliaEstado = function (a, b) {
      let primero = a.familiaEstado;
      let segundo = b.familiaEstado;
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };
    const sortServicioEstado = function (a, b) {
      let primero = a.estado;
      let segundo = b.estado;
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };

    const loadingAprobar = ref(false);

    const handleEdit = (index, row) => {
      router.push(`/listados/servicios-programados/${row.id}`);
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const onAprobar = async (row) => {
      if (!module.servicio) return;

      if (!row.familiaAprobada) return;

      console.log("SERVICIO: ", module.servicio);

      const solicitudesAprobadas =
        module.servicio.peticionFormularios?.filter(
          (p) => p.estado === "INCORPORADO"
        ).length || 0;
      const plazasTotales = module.servicio.plazas;
      const comarcaId = module.servicio.comarcaId;
      const idServicio = module.servicio.id;

      if (solicitudesAprobadas >= plazasTotales) {
        const swalResult = await swalWithBootstrapButtons.fire({
          title: t("dialogs.aviso.title"),
          width: 600,
          html: t(
            "dialogs.admisionSolicitudesServiciosColectivos.limitePlazas.body",
            {
              plazasTotales: plazasTotales,
              solicitudesAprobadas: solicitudesAprobadas,
            }
          ),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: t(
            "dialogs.admisionSolicitudesServiciosColectivos.okButton"
          ),
          cancelButtonText: t("dialogs.cancelButton"),
          reverseButtons: true,
        });

        if (!swalResult.isConfirmed) return;
      }

      loadingAprobar.value = true;
      try {
        await module
          .aprobarSolicitud({
            comarcaId: comarcaId,
            id: idServicio as string,
            peticionFormularioId: row.id,
          })
          .then(() => {
            if (!module.errors?.error) {
              Swal.fire({
                text: t(
                  "formularios.admitirSolicitudServicioColectivo.mensaje.exito"
                ),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            } else {
              if (module.errors.error.statusCode === 422) {
                Swal.fire({
                  text: t(
                    "dialogs.admitirSolicitudServicioColectivo.yaAdmitido.errorText"
                  ),
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } else {
                swalWithBootstrapButtons.fire(
                  t("dialogs.error"),
                  t("dialogs.admitirSolicitudServicioColectivo.errorText"),
                  "error"
                );
              }
            }
          });
      } catch (err) {
        console.error("Error aprobar", err);
        Swal.fire({
          text: t("formularios.serviciosColectivos.aprobar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        loadingAprobar.value = false;
      }
    };

    const verSolicitud = (row) => {
      router.push(`/listados/servicios-programados-solicitudes/${row.id}`);
    };

    const registros = computed(() => {
      return props.servicios?.map((row) => {
        return {
          ...row,
          familiaEstado: row.familiaEstado,
          fechaDate: new Date(row.fecha),
          responsable: row.nombre ? row.nombre + " " + row.apellidos : "",
          menoresNombres: row.menores
            ?.map((menor) => menor.nombre + " " + menor.apellidos)
            .join(", "),
          fechaNacimientoDate:
            row.menores && row.menores.length
              ? new Date(row.menores[0].fechaNacimiento)
              : null,
        };
      });
    });
    const countAprobados = computed(() => {
      return (
        props.servicios?.filter(
          (ele) => ele.estado === FormularioEstado.incorporado
        ).length || 0
      );
    });
    const countPeticiones = computed(() => {
      return props.servicios?.length || 0;
    });
    const loading = ref(false);

    return {
      serviciosListado: computed(() =>
        registros.value?.filter((data) => {
          return (
            !search.value ||
            textSearch(data.responsable, search.value) ||
            textSearch(t(`estados.${data.familiaEstado}`), search.value) ||
            textSearch(t(`formularios.estado.${data.estado}`), search.value) ||
            textSearch(data.menoresNombres, search.value) ||
            textSearch(data.dni, search.value) ||
            textSearch(data.telefono, search.value) ||
            textSearch(data.email, search.value)
          );
        })
      ),
      csvLabels,
      csvFields,
      csvName,
      loadingAprobar,
      search,
      sortFamiliaEstado,
      sortServicioEstado,
      handleEdit,
      onAprobar,
      verSolicitud,
      FormularioEstado,
      toLocaleDateString,
      countAprobados,
      countPeticiones,
      pendiente: (row) => {
        if (!module.servicio) return;

        const comarcaId =
          (userComarcaId as string) || module.servicio.comarcaId;
        const idServicio = module.servicio.id;
        loading.value = true;
        return module
          .pendiente({
            comarcaId: comarcaId,
            id: idServicio as string,
            peticionFormularioId: row.id,
          })
          .then(() => {
            loading.value = false;
            return Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.exito"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .catch(() => {
            loading.value = false;
            Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      },
      listaDeEspera: (row) => {
        if (!module.servicio) return;

        const comarcaId =
          (userComarcaId as string) || module.servicio.comarcaId;
        const idServicio = module.servicio.id;
        loading.value = true;
        return module
          .listaDeEspera({
            comarcaId: comarcaId,
            id: idServicio as string,
            peticionFormularioId: row.id,
          })
          .then(() => {
            loading.value = false;
            return Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.exito"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .catch(() => {
            loading.value = false;
            Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      },
      rechazar: (row) => {
        if (!module.servicio) return;

        const comarcaId =
          (userComarcaId as string) || module.servicio.comarcaId;
        const idServicio = module.servicio.id;
        loading.value = true;
        return module
          .rechazarSolicitud({
            comarcaId: comarcaId,
            id: idServicio as string,
            peticionFormularioId: row.id,
          })
          .then(() => {
            loading.value = false;
            return Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.exito"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .catch(() => {
            loading.value = false;
            Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      },
      realizadaPorError: (row) => {
        if (!module.servicio) return;

        const comarcaId =
          (userComarcaId as string) || module.servicio.comarcaId;
        const idServicio = module.servicio.id;
        loading.value = true;
        return module
          .realizadoPorErrorSolicitud({
            comarcaId: comarcaId,
            id: idServicio as string,
            peticionFormularioId: row.id,
          })
          .then(() => {
            loading.value = false;
            return Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.exito"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .catch(() => {
            loading.value = false;
            Swal.fire({
              text: t("dialogs.cambiarEstadoServicioColectivo.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      },
    };
  },
});
