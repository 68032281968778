
import AsignarProfesionalModal from "@/components/modals/AsignarProfesionalModal.vue";
import DrawerFichaProfesional from "@/components/servicios/DrawerFichaProfesional.vue";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { titulacionesTodas } from "@/core/data/titulaciones";
import {
  ProfesionalListado,
  ServicioListadoModule,
  ServicioProfesionalListado,
} from "@/store/modules";
import moment from "moment";
import "moment/locale/es";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "profesionales-listado",
  components: {
    "download-excel": JsonExcel,
    DrawerFichaProfesional,
    AsignarProfesionalModal,
  },
  props: {
    profesionales: Object as () => ServicioProfesionalListado[] | null,
    inicioPrevisto: Date,
    finPrevisto: Date,
    comarcaId: String,
    servicioId: String,
  },

  setup(props) {
    const { t } = useI18n();

    const csvLabels = {
      nombre: t("csv.listados.profesionales.nombre"),
      apellidos: t("csv.listados.profesionales.apellidos"),
      telefono: t("csv.listados.profesionales.telefono"),
      email: t("csv.listados.profesionales.email"),
      titulacion: t("csv.listados.profesionales.titulacion"),
    };

    const store = useStore();
    const module = getModule(ServicioListadoModule, store);

    const route = useRoute();
    const token = route.params.id as string;

    const csvFields = [
      "nombre",
      "apellidos",
      "telefono",
      "email",
      "titulacionText",
    ];
    const csvName =
      "serviciosProfesionalesListado" + new Date().getTime() + ".xlsx";

    const drawerFicha = ref(false);
    const fechaInicioCompleta = ref<string>("");
    const fechaFinCompleta = ref<string>("");

    const sortNombreApellidos = function (a, b) {
      let primero = a.nombre + " " + a.apellidos;
      let segundo = b.nombre + " " + b.apellidos;
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };

    const loadingAsignarProfesional = ref(false);

    const profesionalSeleccionado = ref<ProfesionalListado | null>(null);
    const error = computed(() => module.errors);
    return {
      error,
      titulacionesTodas,
      profesionalesListado: computed(
        () =>
          props.profesionales?.map((row) => {
            return {
              ...row,
              titulacionText:
                row.titulacion
                  ?.map((t) => titulacionesTodas.filter((a) => a.id == t))
                  ?.map((t) => t.map((r) => r.text))
                  .join(", ") || "",
            };
          }) ?? []
      ),
      csvLabels,
      csvFields,
      csvName,
      drawerFicha,
      profesionalSeleccionado,
      loadingAsignarProfesional,
      drawerCerrar: () => {
        drawerFicha.value = false;
      },
      verFichaProfesional: (index, row) => {
        profesionalSeleccionado.value = row;
        drawerFicha.value = true;
      },
      seleccionarProfesional: (index, row) => {
        profesionalSeleccionado.value = row;
        fechaInicioCompleta.value =
          moment(props.inicioPrevisto).format("dddd") +
          " " +
          moment(props.inicioPrevisto).format("LL");
        fechaFinCompleta.value =
          moment(props.finPrevisto).format("dddd") +
          " " +
          moment(props.finPrevisto).format("LL");
      },
      fechaInicioCompleta,
      fechaFinCompleta,
      sortNombreApellidos,
      token,
    };
  },
});
