const iconosServicios = [
  { cls: "fas fa-campground cadetblue", label: "Campamento - Ludoverano" },
  { cls: "fas fa-school darkorange", label: "Colegio - Extraescolares" },
  { cls: "fas fa-tree chocolate", label: "Naturaleza" },
  { cls: "fas fa-robot lightslategrey", label: "Informática" },
  { cls: "fas fa-snowman cornflowerblue", label: "Navidad - Invierno" },
  { cls: "fas fa-clock lightcoral", label: "Reloj - Madrugadores" },
  { cls: "fas fa-theater-masks indianred", label: "Semana Blanca - Carnaval" },
  { cls: "fas fa-skiing grey", label: "Semana Blanca - Carnaval" },
  { cls: "fas fa-puzzle-piece burlywood", label: "Ludoteca - Extraescolares" },
  { cls: "fas fa-chess goldenrod", label: "Ludoteca - Extraescolares" },
  { cls: "fas fa-snowflake lightblue", label: "Navidad - Invierno" },
  { cls: "fas fa-book darkseagreen", label: "Estudio - Extraescolares" },
  { cls: "fas fa-table-tennis mediumpurple", label: "Deportes" },
  {
    cls: "fas fa-hands-helping dodgerblue",
    label: "Cuidados - Conciliación",
  },
  {
    cls: "fas fa-hand-holding-heart orchid",
    label: "Cuidados - Conciliación",
  },
  { cls: "fas fa-utensils mediumturquoise", label: "Comedor" },
];

export default iconosServicios;
