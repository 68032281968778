import { IAMFile } from "@/types";
import { ref, onMounted } from "vue";
import Dropzone from "dropzone";
import imageCompression from "browser-image-compression";

export function useDropZone(elementId) {
  const nuevosArchivos = ref<IAMFile[]>([]);

  const deleteAdjuntoNuevo = (file) => {
    nuevosArchivos.value = nuevosArchivos.value.filter(function (item) {
      return item.nombre !== file.name;
    });
  };
  const compressImage = (file) => {
    const compressionOptions = { maxSizeMB: 0.3 };
    return imageCompression(file, compressionOptions);
  };
  const includeAdjuntoNuevo = (file) => {
    const reader = new FileReader();
    return new Promise<void>((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        nuevosArchivos.value.push({
          nombre: file.name,
          data: reader.result?.toString() || "",
        });
        resolve();
      };
      reader.onerror = reject;
    });
  };
  onMounted(() => {
    const dropzoneNuevosDocumentos = new Dropzone(elementId, {
      url: process.env.VUE_APP_API_URL,
      // The name that will be used to transfer the file
      paramName: "documento",
      maxFilesize: 10, // MB
      addRemoveLinks: true,
      uploadMultiple: true,
      autoProcessQueue: false,
      acceptedFiles: "image/*,application/pdf",
    });
    dropzoneNuevosDocumentos.on("addedfile", async (file) => {
      const fileToAdd = file.type.includes("image/")
        ? await compressImage(file)
        : file;

      includeAdjuntoNuevo(fileToAdd);
    });
    dropzoneNuevosDocumentos.on("removedfile", deleteAdjuntoNuevo);
  });

  return {
    nuevosArchivos,
    deleteAdjuntoNuevo,
    includeAdjuntoNuevo,
  };
}
