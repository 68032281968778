
import { useModal } from "@/composable/useModal";
import {
  tipologiasContrato,
  tipologiasJornada,
} from "@/core/data/tipologiasTrabajo";
import router from "@/router";
import {
  ServicioListadoModule,
  ServicioProfesionalListado,
} from "@/store/modules";
import Swal from "sweetalert2";
import { useField, useForm } from "vee-validate";
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface FormValues {
  tipologiaJornada: string;
  tipologiaContrato: string;
}

export default defineComponent({
  name: "modal-asignar_profesional",
  props: {
    comarcaId: String,
    servicioId: String,
    profesionalSeleccionado: Object as () => ServicioProfesionalListado | null,
    fechaInicioCompleta: String,
    fechaFinCompleta: String,
  },
  setup(props) {
    const store = useStore();
    const module = getModule(ServicioListadoModule, store);
    const { t } = useI18n();
    const { modalRef, isShown } = useModal();

    const nombreCompleto = ref<string>("");
    const descripcion = ref<string>("");

    watch(
      () => [props.profesionalSeleccionado, isShown.value],
      () => {
        if (!props.profesionalSeleccionado) return;

        nombreCompleto.value =
          props.profesionalSeleccionado.nombre +
          " " +
          props.profesionalSeleccionado.apellidos;
        descripcion.value = t("dialogs.asignarProfesional.body", {
          nombreCompleto: nombreCompleto.value,
          fechaInicioCompleta: props.fechaInicioCompleta,
          fechaFinCompleta: props.fechaFinCompleta,
        });
        resetForm({
          values: {
            tipologiaJornada: "",
            tipologiaContrato: "",
          },
        });
      }
    );

    const validationSchema = Yup.object({
      tipologiaJornada: Yup.string()
        .required()
        .label(t("formularios.asignarProfesional.tipologiaJornada.label")),
      tipologiaContrato: Yup.string()
        .required()
        .label(t("formularios.asignarProfesional.tipologiaContrato.label")),
    });

    const { handleSubmit, resetForm, meta, errors } = useForm<FormValues>({
      validationSchema,
    });
    const { value: tipologiaJornada } = useField("tipologiaJornada");
    const { value: tipologiaContrato } = useField("tipologiaContrato");

    const loading = ref(false);
    const submit = handleSubmit(async (values) => {
      if (
        !props.comarcaId ||
        !props.servicioId ||
        !props.profesionalSeleccionado?.id
      )
        return;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      loading.value = true;
      try {
        await module
          .asignarProfesional({
            comarcaId: props.comarcaId as string,
            idServicio: props.servicioId as string,
            idProfesional: props.profesionalSeleccionado.id,
            ...values,
          })
          .then(() => {
            if (!module.errors?.error) {
              Swal.fire({
                text: t("formularios.servicioDetalle.asignar.mensaje.exito"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.go(0);
              });
            } else {
              swalWithBootstrapButtons.fire(
                t("dialogs.error"),
                t("dialogs.asignarProfesional.errorText"),
                "error"
              );
            }
          });
      } catch (err) {
        Swal.fire({
          text: t("formularios.profesionalesDisponiblesListado.asignar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        loading.value = false;
      }
    });

    return {
      meta,
      submit,
      loading,
      errors,
      modalRef,
      tipologiasJornada,
      tipologiasContrato,
      tipologiaJornada,
      tipologiaContrato,
      nombreCompleto,
      descripcion,
    };
  },
});
