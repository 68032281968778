
import { horarios } from "@/core/data/tiemposFamilias";
import type { ElDrawer } from "element-plus";
import moment from "moment";
import "moment/locale/es";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, ref } from "vue";
import * as Yup from "yup";

interface Fecha {
  formAddDateFecha?: Date | null;
  formAddDateHorario?: number | null;
}

export default defineComponent({
  name: "formulario-fecha-servicio",
  components: {
    ErrorMessage,
  },
  props: {
    onAceptar: {
      type: Function,
      required: true,
    },
    cerrar: {
      type: Function,
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const drawerRef = ref<InstanceType<typeof ElDrawer>>();

    const drawer = computed({
      get: () => props.display,
      set: (value) => emit("update:display", value),
    });

    const schema = Yup.object({
      formAddDateFecha: Yup.date().required().label("Fecha"),
      formAddDateHorario: Yup.number().required().label("Horario"),
    });

    const { resetForm, handleSubmit } = useForm<Fecha>({
      validationSchema: schema,
    });
    const { value: formAddDateFecha } = useField<Date | null>(
      "formAddDateFecha"
    );
    const { value: formAddDateHorario } = useField<number | null>(
      "formAddDateHorario"
    );

    return {
      drawer,
      drawerRef,
      horarios,
      formAddDateFecha,
      formAddDateHorario,
      onAddFecha: handleSubmit(() => {
        let fechaToShow = moment(formAddDateFecha.value).format("D/M/Y");
        let fecha = moment(formAddDateFecha.value)
          .add(formAddDateHorario.value, "hours")
          .format();
        let diaSemana = moment(fecha).locale("es").format("dddd");
        let hora = horarios.filter((h) => h.id == formAddDateHorario.value)[0][
          "text"
        ];
        const fila = {
          fechaToShow: fechaToShow,
          fecha: fecha,
          diaSemana: diaSemana,
          hora: hora,
        };

        props.onAceptar && props.onAceptar(fila);
        resetForm({
          values: {
            formAddDateFecha: null,
            formAddDateHorario: null,
          },
        });
      }),
      cancelForm: () => {
        props?.cerrar();
        resetForm({
          values: {
            formAddDateFecha: null,
            formAddDateHorario: null,
          },
        });
      },
    };
  },
});
