export const tipologiasJornada = [
  { id: "Jornada completa", nombre: "Jornada completa" },
  { id: "Jornada parcial", nombre: "Jornada parcial" },
  { id: "No consta", nombre: "No consta" },
];

export const tipologiasContrato = [
  { id: "Indefinido", nombre: "Indefinido" },
  { id: "Temporal", nombre: "Temporal" },
  { id: "Fijo-Discontinuo", nombre: "Fijo-Discontinuo" },
  { id: "Formativo", nombre: "Formativo" },
  { id: "No consta", nombre: "No consta" },
];
